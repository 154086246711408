import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal, Box, Typography } from '@mui/material';
import {Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { VpnLock } from '@mui/icons-material';
import { valid } from 'chroma-js';
import MDBadge from 'components/MDBadge';
import DeleteIcon from '@mui/icons-material/Delete'; 

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
  }
`;
const MyModal = (isOpen) => { 



  const [scantypes,setScanTypes]=useState([]);
  const [bodyparts,setBodyParts]=useState([]);
  const [scanTypeId,setScanTypeId]=useState('');
  const [selectedbodyparts,SetSelectedBodyParts]=useState([]);
  const [dbBodyParts,setDbBodyParts]=useState([]);
  const [selectedOption, setSelectedOption] = useState("Select Scan Type");

console.log('selected bodyparts',selectedbodyparts);
    useEffect(()=>{
      
      setBodyParts([]);
      SetSelectedBodyParts([])
     setSelectedOption("Select Scan Type")
     setScanTypeId('');
    },[isOpen])

useEffect(() => {
  try{
  fetch(process.env.REACT_APP_API_URL + `/auth/takedatascantype`,)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setScanTypes(data);
    });
  }catch(error){
    console.log(error)
  }
},[])


useEffect(() => {
  try{
    if(scanTypeId==undefined){
      setBodyParts([]);
      SetSelectedBodyParts([])
      return
    }
  fetch(process.env.REACT_APP_API_URL + `/auth/bodyparts/`+scanTypeId,)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setBodyParts(data);
    });
  }catch(error){
    console.log(error)
  }
},[scanTypeId])


useEffect(()=>{

  if(scanTypeId==''){
    return
  }
  try{
  fetch( process.env.REACT_APP_API_URL+`/search/getbodypartsscantypeswithprice/${isOpen.centerId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    data.map((item)=>{
     if(item.id==scanTypeId){
      console.log('itemm',item)
      item.bodyparts.map((val)=>(

        setBodyParts((prevBodyParts) =>
          prevBodyParts.filter((item) => item.body_part.id!==val.id)),
        setDbBodyParts((prevParts) => [...prevParts, {body_part:val}]),
        SetSelectedBodyParts((prevParts) => [...prevParts, {body_part:val}])
      ))
    
     }
    })
  });
}catch(error){
  console.log(error);
}

},[scanTypeId])
const handleChange = (event) => {
  const value=event.target.value;
  const split=value.split('#');

 setScanTypeId(split[1]);
 SetSelectedBodyParts([]);
 setSelectedOption(event.target.value); 
};


const handlesubmit=(e)=>{
  e.preventDefault()
 
 const values= selectedbodyparts.map((data)=>{
  let obj={};
  if(data.price){
     obj={
      body_part_id_fkey:data.body_part.id,
      price:data.price
    }
  }else{
     obj={
      body_part_id_fkey:data.body_part.id,
      price:data.body_part.price
    }
  }
    
    return obj;
})


   fetch( process.env.REACT_APP_API_URL+`/search/addbodypartsscantypeswithprice/${isOpen.centerId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            scan_type_id_fkey:scanTypeId,
            body_parts:values
    
          })
        })
        .then(response => response.json())
        .then(data => {
          if(data.message){
            isOpen.onUpdate({
              message:data.message,
            });
          }else{
            isOpen.onUpdate({
              error:data.error
            });
          }
        });
  
}

const renderValue = (selected) => {
  ///console.log("selected>",selected)
  return selected.split('#')[0];
};

  const selectedBodyPart=(data)=>{
  
  setBodyParts((prevBodyParts) =>
    prevBodyParts.filter((item) => item!== data)
  );
  SetSelectedBodyParts((prevBodyPart) => [...prevBodyPart, data]);
  
}


const handlePriceChange = (event, selectedItem) => {
  const updatedSelectedBodyParts = selectedbodyparts.map((part) => {
    if (part === selectedItem) {
      return { ...part, body_part: { ...part.body_part, price: event.target.value } };
    }
    return part;
  });

  SetSelectedBodyParts(updatedSelectedBodyParts);
};

const handleDelete=(item)=>{

  const bodypart_id=item.body_part.id;


  dbBodyParts.map((item)=>{
    if(item.body_part.id===bodypart_id){
      fetch(process.env.REACT_APP_API_URL + `/search/deletebodypartfromcenter/${isOpen.centerId}/${scanTypeId}/${bodypart_id}`,
        {
        method: 'DELETE'
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('delete',data);
      });
    }

  })
 
SetSelectedBodyParts((prevBodyPart) => prevBodyPart.filter((part) => part !== item));
 



  
}

  return (
    <>
    <Modal
    open={isOpen.isOpen}
    onClose={isOpen.onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={isOpen.isOpen}>
      <MDBox
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        shadow="md"
        borderRadius="lg"
        p={4}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
        }}
      >
        <MDBox width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h6">Select ScanType</MDTypography>
              <FormControl fullWidth>
                <StyledSelect
                  id="demo-simple-select"
                  value={selectedOption}
                  onChange={handleChange}
                  renderValue={renderValue}
                >
                  <MenuItem   value={"Select Scan Type"}>
                  Select Scan Type
                 </MenuItem>
                  {scantypes.map((item) => (
                 <MenuItem key={item.id} value= {item.name+"#"+item.id} >
                   {item.name}
                 </MenuItem>
               ))}
                </StyledSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',marginTop: '50px',gap: '20px' }}>
          <Typography variant="h6" sx={{ position: 'absolute', top: 125, left: 35 }}>BodyParts</Typography>
          <Box sx={{backgroundColor: '#F5F5F5', height: '400px', width: '400px', border: '1px solid gray', borderRadius: '10px',  overflow: 'auto', }}>
          {bodyparts.map((item) => (
            
             <MDTypography component="a" href="#"  key={item.body_part.id} onClick={() => selectedBodyPart(item)}>
               <MDBox sx={{ flexGrow: 1 }}>
                 <MDBadge badgeContent={item.body_part.name} color="success" variant="gradient" size="sm" />
               </MDBox>
             </MDTypography>
             
         
             ))}
          </Box>
          <Typography variant="h6" sx={{ position: 'absolute', top: 125, left: 315 }}>Selected BodyParts</Typography>
          <Box sx={{ backgroundColor: '#F5F5F5',height: '400px', width: '400px',border: '1px solid gray', borderRadius: '10px', overflow: 'auto', }}>
          {selectedbodyparts.map((item) => (
              
            <MDTypography component="a" href="#"  key={item.body_part.id}  >
      
               <MDBox sx={{ flexGrow: 1 }}>
               <span style={{marginLeft:"10px"}} onClick={()=>handleDelete(item)} > 
              <DeleteIcon fontSize="small" color="error" />
              </span>
               <MDBadge badgeContent={item.body_part.name}  color="success" variant="gradient" size="sm" />
              </MDBox>
                    
             
              <MDInput
                 placeholder="price"
                 type="number"
                 name="price"
                 value={item.body_part.price}
                 onChange={(e)=>handlePriceChange(e,item)}
                 style={{ width: '90%' }}
                 sx={{ marginLeft: '10px', marginRight: '10px' }}
               />
              </MDTypography>
             ))}
          </Box>
        </Box>
          <MDBox
            display="flex"
            justifyContent="flex-end"
            gap={2}
            mt={2}
          >
             <MDButton
              color="success"
              onClick={handlesubmit}
            >
              Submit
            </MDButton>
          
              
            
            <MDButton
              color="secondary"
              variant="outlined"
              onClick={isOpen.onClose}
            >
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Fade>
  </Modal>

    </>
  );
};

export default MyModal;