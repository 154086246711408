import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';

const MyModal = (isOpen) => {

  const [firstName, setFirstName] = useState(  "");
  const [lastName, setLastName] = useState(  "");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState( "");
  const [location, setLocation] = useState( "");
  const [desc, setDesc] = useState( "");
  const [image, setImage] = useState([]);

  const [data,setData]=useState([]);

  useEffect(() => {
      try {
        fetch(process.env.REACT_APP_API_URL + `/auth/getadminbyid/${isOpen.admin_id}`, {
          method: 'GET',
        })
          .then(response => response.json())
          .then(data => {
            setData(data);
          })

      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
   

  }, []);

  const handleImageChange = (event) => {
    ///console.log('event',event.target.files[0].name);
    const file = event.target.files[0].name; 
    if (file) {
      setImage(file); 
    }
  }
  const handleSave = () => {

    console.log('fileeeeee',image);
  fetch( process.env.REACT_APP_API_URL+`/auth/updateadmin/${isOpen.admin_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname:firstName,
          lastname:lastName,
          email:email,
          phonenumber:phoneNumber,
          location:location,
          description:desc,
          image:image
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('dataaa',data);
      if(data.message){
        isOpen.onUpdate({
          message:data.message,
          firstname:firstName,
          lastname:lastName,
          email:email,
          phonenumber:phoneNumber,
          location:location,
          description:desc,
          image:image,
          status:true,
        });
      }else{
        isOpen.onUpdate({
          error:data.error,
        });
      }
        
      })
     
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <MDTypography>
                First Name
                  </MDTypography>
                  <MDInput
                    placeholder="Last Name"
                    style={{ width: '100%' }} 
                    value={data.firstname}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                Last Name
                  </MDTypography>
                  <MDInput
                    placeholder="First Name"
                    style={{ width: '100%' }} 
                    value={data.lastname}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
                <MDTypography>
                Email
                  </MDTypography>
                  <MDInput
                    placeholder="Email"
                    type="email"
                    value={data && (data.email)}
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                Phone Number
                  </MDTypography>
                  <MDInput
                    placeholder="Phone Number"
                   value={data.phonenumber}
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>

               
                
            
                   
                <Grid item xs={12}>
                <MDTypography>
                   Location
                  </MDTypography>
                  <MDInput
                   type="text"
                   value={data.location}
                    placeholder="location"
                    style={{ width: '100%' }} 
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </Grid>

                   
                <Grid item xs={12}>
                <MDTypography>
                   Description
                  </MDTypography>
                  <MDInput
                   type="text"
                    placeholder="desc"
                    value={data.description}
                    style={{ width: '100%' }} 
                    onChange={(event) => setDesc(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
                <MDTypography>
                   Image
                  </MDTypography>
                  <MDInput
                   type="file"
                    style={{ width: '100%' }} 
                    onChange={handleImageChange} 
                  />
                </Grid>
                
              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  onClick={handleSave}
                >
                  Update
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;