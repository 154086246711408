/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Badge, Box, Button, CardContent, Divider, Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useParams } from 'react-router-dom';
import DoctorPopUp from "components/AssignDoctorPopUp";

function BookingDetails() {


  const [ispopupOpen, setPopupOpen] = useState(false);

  const [doctorname, setDoctorname] = useState('');
  const [successSB, setSuccessSB] = useState(false);

  const[title, setTitle] = useState('');
  const[message, setMessage] = useState('');

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);


  const closePopup=()=>{
    setPopupOpen(false);
  }


  const { id } = useParams();
  const [bookingDetail, setBookingDetail] = useState({});
  const [loading, setLoading] = useState(false); 
useEffect(() => {
   
  fetch( process.env.REACT_APP_API_URL+`/booking/getonebookingdetail/${id}`)
  .then(response => response.json())
  .then(data => {
  console.log(data);
    setBookingDetail(data);
    setLoading(true);
  })
  .catch((error) => {
    console.error('Error:', error);
    setLoading(false);
  });
  
}, []);

const update=(value)=>{
  setPopupOpen(false);
  setDoctorname(value.doctor_name);
  setMessage('Doctor Assigned');
  setTitle('Updated');
  openSuccessSB();
//  console.log('return values>>>>>>>>',value);

}

const openPopup=()=>{
  setPopupOpen(true);
}



const renderSuccessSB = (
  <MDSnackbar
    color="success"
    icon="check"
    title={title}
    content={message}
    open={successSB}
    onClose={closeSuccessSB}
    close={closeSuccessSB}
    bgWhite
  />
);

const viewreferralreport=()=>{
  window.open(bookingDetail[0].referralfilepath, '_blank');
}
const viewdocotorreport=()=>{
  window.open(bookingDetail[0].doctorfilepath, '_blank');
}
const viewpatinetreport=()=>{
  window.open(bookingDetail[0].filepath, '_blank');
}

if (loading) {
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <Box p={3}>
    
      <Divider sx={{ my: 3 }} />
  
      {/* Main Content */}
      <Box bgcolor="white" borderRadius="lg" p={2} mx={2}>
        <Grid container spacing={3}>
        
          <Grid item xs={12} md={8}>
          
            <Box mb={3}>
            <MDBox
             
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Scan Details
              </MDTypography>
            </MDBox>
            <Grid container spacing={2} mt={1}>
    {/* Each Row */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Doctor Name</Typography>
                  {doctorname? <Typography variant="body2" ><Grid container justifyContent="space-between" alignItems="center">
                    <Grid item></Grid>
                      <Button variant="contained" color="success" onClick={openPopup}>{doctorname}</Button>
                  </Grid></Typography> :(
                  <Typography variant="body2" > 
                   <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item></Grid>
                      <Button variant="contained" color="success" onClick={openPopup}>{bookingDetail[0].doctor? (bookingDetail[0].doctor.firstname+" "+bookingDetail[0].doctor.lastname) :'Assign Doctor'}</Button>
                  </Grid>
            </Typography>)}
                </Box>
              </Grid>
              <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                {/* Label on the left side */}
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Imaging Center</Typography>
                {/* Details on the right side */}
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {bookingDetail[0].center ? bookingDetail[0].center.name : ''}
                  </Typography>
                  <Typography variant="body2" >
                    {bookingDetail[0].center ? bookingDetail[0].center.location : ''}
                  </Typography>
                  <Typography variant="body2">
                    {bookingDetail[0].center ? bookingDetail[0].center.address : ''}
                  </Typography>
                </Box>
              </Box>
            </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Modality</Typography>
                  <Typography variant="body2">{bookingDetail[0].scan_type ? bookingDetail[0].scan_type.name : ''}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  {/* Label on the left side */}
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Body Parts</Typography>
                  {/* Details on the right side */}
                  <Box display="flex" flexDirection="column" alignItems="flex-end">
                    {bookingDetail[0].body_parts ? (
                      bookingDetail[0].body_parts.map((item) => (
                        <Typography key={item.name} variant="body2">
                          {item.name}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">No data available</Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Reason</Typography>
                  <Typography variant="body2">{bookingDetail[0].reason? bookingDetail[0].reason : ''}</Typography>
                </Box>
              </Grid>
            </Grid>

            </Box>
  
            <Divider sx={{ my: 3 }} />
            <Box mb={3}>
            <MDBox
              
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >

                <MDTypography variant="h6" color="white">
                  Patient Details
                </MDTypography>
              </MDBox>
              <Grid container spacing={2} mt={1}>
              {/* Each Row */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Name</Typography>
                  <Typography variant="body2">{bookingDetail[0].patient_detail? bookingDetail[0].patient_detail.firstname+" "+bookingDetail[0].patient_detail.lastname : ''}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Email</Typography>
                  <Typography variant="body2">{bookingDetail[0].patient_detail? bookingDetail[0].patient_detail.email : ''}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Gender</Typography>
                  <Typography variant="body2">{bookingDetail[0].patient_detail? bookingDetail[0].patient_detail.gender : ''}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>  Phone Number</Typography>
                  <Typography variant="body2">{bookingDetail[0].patient_detail? bookingDetail[0].patient_detail.phone_numbers : ''}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Date of Birth</Typography>
                  <Typography variant="body2">{bookingDetail[0].patient_detail? bookingDetail[0].patient_detail.dob : ''}</Typography>
                </Box>
              </Grid>
            </Grid>

            </Box>
            <Divider sx={{ my: 3 }} />
            <Box mb={3}>
            <MDBox
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >

              <MDTypography variant="h6" color="white">
                Reports
              </MDTypography>
            </MDBox>
            <Grid container spacing={2} mt={1}>
              {/* Each Row */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Patient Report</Typography>
                  <Typography variant="body2">{bookingDetail[0].filepath? <Typography variant="body2" ><Grid container justifyContent="space-between" alignItems="center">
                    <Grid item></Grid>
                      <Button variant="contained" color="success" href={bookingDetail[0].filepath}>Download</Button>
                      <Button sx={{ mx: 2 }} variant="contained" color="success" onClick={viewpatinetreport}>View</Button>
                  </Grid></Typography> : 'No Document'}</Typography>
                </Box>
              </Grid>
            
            </Grid>
            <Grid container spacing={2} mt={1}>
              {/* Each Row */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Doctor Report</Typography>
                  <Typography variant="body2">{bookingDetail[0].doctorfilepath? <Typography variant="body2" ><Grid container justifyContent="space-between" alignItems="center">
                    <Grid item></Grid>
                      <Button variant="contained" color="success" href={bookingDetail[0].doctorfilepath}>Download</Button>
                      <Button sx={{ mx: 2 }} variant="contained" color="success" onClick={viewdocotorreport}>View</Button>
                  </Grid></Typography> : 'No Document'}</Typography>
                </Box>
              </Grid>
           
            </Grid>
            <Grid container spacing={2} mt={1}>
              {/* Each Row */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Referral Report</Typography>
                  <Typography variant="body2">{bookingDetail[0].referralfilepath? <Typography variant="body2" ><Grid container justifyContent="space-between" alignItems="center">
                    <Grid item></Grid>
                      <Button variant="contained" color="success" href={bookingDetail[0].referralfilepath}>Download</Button>
                      <Button sx={{ mx: 2 }} variant="contained" color="success" onClick={viewreferralreport}>View</Button>
                  </Grid></Typography> : 'No Document'}</Typography>
                </Box>
              </Grid>
              
             
            </Grid>
            </Box>
          </Grid>
  
          {/* Right Section */}
          <Grid item xs={12} md={4}>
    <Box mb={3} p={2} bgcolor="grey.100">
      <Typography variant="h6" fontWeight="bold">{bookingDetail[0].patient_detail? bookingDetail[0].patient_detail.firstname+" "+bookingDetail[0].patient_detail.lastname : ''} Q/A</Typography>
      <Box mt={2}>
        {bookingDetail[0].booking_answers? bookingDetail[0].booking_answers.map((item) => (
           <Box key={item.answer} mt={2} sx={{ borderBottom: '1px solid grey', pb: 1 }}>
           <Typography variant="body2" fontWeight="bold">Q: {item.question? item.question.question_text : ''}</Typography>
           <Typography variant="caption" fontWeight="bold" color="textSecondary">
             A: {item.answer}
           </Typography>
         </Box>

        )): ''}



      </Box>
    </Box>

    <DoctorPopUp   isOpen={ispopupOpen} onClose={closePopup} booking_id={id} onUpdate={update} doctor_name={bookingDetail[0].doctor? (bookingDetail[0].doctor.firstname+" "+bookingDetail[0].doctor.lastname) :''}/>
      </Grid>
        </Grid>
      </Box>
    </Box>
    <Footer />
    {renderSuccessSB}
  </DashboardLayout>
  
  );
};
}

export default BookingDetails;
