/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { grey } from '@mui/material/colors';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Type } from "ajv/dist/compile/subschema";
//import { format } from 'date-fns'; 

export default function data() {
  const [user, setUser] = useState([]);
  const navigate = useNavigate(); // Utilize useNavigate for redirection

  // console.log(user[2].patient_detail.firstname);
  const handleDelete = (id) => {
    const result = window.confirm('Do you want to continue?');
    if (result) {

      const booking_id = id;
      fetch(process.env.REACT_APP_API_URL + `/booking/deletebooking/${booking_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          alert(data.message);
        })

    }

  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwt_token');
    if (storedUser && storedToken) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const user_id = parsedUser.id;
        fetch(process.env.REACT_APP_API_URL + `/booking/getallbooking`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        })
          .then(response => response.json())
          .then(data => {
            data.sort((booking1, booking2) => booking1.id - booking2.id);
            console.log(data);
            setUser(data);
          })

      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    } else {
      // No user or token found, redirect to login page
      navigate('/authentication/sign-in'); // Assuming your login page path is '/login'
    }

  }, []);



  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Patient ", accessor: "Patient", align: "left" },
      { Header: "Doctor", accessor: "Doctor", align: "center" },
      { Header: "scan Type", accessor: "Scan_Type", align: "center" },
      { Header: "Center", accessor: "Center", align: "center" },
      { Header: "Status", accessor: "Status", align: "center" },
      { Header: "Date", accessor: "Date", align: "center" },

    ],


    rows: user.map((item) => (
      {
        id: (

          <MDTypography variant="caption" fontWeight="medium">
            <Link to={`/booking/bookingdetails/${item.id}`}>
              {item.id}
            </Link>
          </MDTypography>
        ),

        Patient: (
          item.patient_detail.firstname == '' ? ('No Patient') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.patient_detail.firstname + " " + item.patient_detail.lastname}
            </MDTypography>
          )
        ),

        Doctor: (
          item.doctor == null ? ('No Doctor Assign') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {(item.doctor.firstname + " " + item.doctor.lastname)}
            </MDTypography>
          )
        ),
        Scan_Type: (
          item.scan_type == null ? ('No Scan Type') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.scan_type.name ? (item.scan_type.name) : ('no')}
            </MDTypography>)
        ),
        Center: (
          item.center == null ? ('No Center') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.center.name ? (item.center.name) : ('no')}
            </MDTypography>
          )
        ),
        Status: (
          item.status === 'pending' ? (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status} color={grey[500]} variant="gradient" size="sm" />
            </MDBox>
          ) : item.status === 'cancelled' ? (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status} color="error" variant="gradient" size="sm" />
            </MDBox>
          )
            : (
              <MDBox ml={-1}>
                <MDBadge badgeContent={item.status} color="success" variant="gradient" size="sm" />
              </MDBox>
            )
        ),
        Date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {(item.appointment_date ? item.appointment_date.split(" ")[0] : "") + " AT " + (item.appointment_time ? item.appointment_time : "")}
          </MDTypography>
        ),
        time: item.appointment_time,
        date: item.appointment_date
      })),
  };
}
