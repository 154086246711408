import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';

const MyModal = (isOpen) => {
 

  const [firstName, setFirstName] = useState(isOpen.name[0] || "");
  const [lastName, setLastName] = useState(isOpen.name[1] || "");
  const [email, setEmail] = useState(isOpen.email || "");
  const [phoneNumber, setPhoneNumber] = useState(isOpen.phone_number || "");

  useEffect(()=>{
    setFirstName(isOpen.name[0]);
    setLastName(isOpen.name[1]);
    setEmail(isOpen.email );
    setPhoneNumber(isOpen.phone_number );
  },[isOpen])
  const handleSave = () => {

    const doctor_id=isOpen.id;
  fetch( process.env.REACT_APP_API_URL+`/auth/updatedoctor/${doctor_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname:firstName,
          lastname:lastName,
          email:email,
          phonenumber:phoneNumber,
        })
      })
      .then(response => response.json())
      .then(data => {
        if(data.message){
          isOpen.onUpdate({
            message:data.message,
            doctor_id:doctor_id,
            firstname:firstName,
            lastname:lastName,
            email:email,
            phonenumber:phoneNumber,
        });
        }else{
          isOpen.onUpdate({

            error:data.error,
        });
        }
       
      })
     

 
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <MDTypography>
                   First Name
                  </MDTypography>
                  <MDInput
                    placeholder="First Name"
                    value={firstName}
                    style={{ width: '100%' }} 
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                   Last Name
                  </MDTypography>
                  <MDInput
                    placeholder="Last Name"
                    value={lastName}
                    style={{ width: '100%' }} 
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                   Email
                  </MDTypography>
                  <MDInput
                    placeholder="Email"
                    type="email"
                    value={email}
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                Phone Number
                  </MDTypography>
                  <MDInput
                    placeholder="Phone Number"
                    value={phoneNumber}
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>
              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  onClick={handleSave}
                >
                  Update
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;