import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal, FormControlLabel, Checkbox, Typography } from '@mui/material';



const MyModal = (isOpen) => {

  const [Name, setName] = useState(  "");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState( "");
  const [postcode, setPostcode] = useState(  "");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState( "");
  const [checked, setChecked] = useState(true);
  const [image, setImage] = useState([]);

  
  const handleImageChange = (event) => {
    ///console.log('event',event.target.files[0].name);
    const file = event.target.files[0]; 
    if (file) {
      setImage(file); 
    }
  }
  

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(()=>{
    setChecked(true);
  },[isOpen])


  const handleSave = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', Name);
    formData.append('email', email);
    formData.append('image', image); // Ensure 'image' is a File or Blob object
    formData.append('phone_number', phoneNumber);
    formData.append('location',postcode);
    formData.append('address',address);
    formData.append('password', password);


     fetch( process.env.REACT_APP_API_URL+`/auth/register-centers`, {
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.message);
        if(data.message){
        isOpen.onAdd({
          center_id:data.UserId,
          message:data.message,
          name:Name,
          email:email,
          phonenumber:phoneNumber,
          address:address,
          status:true,
          checked:checked
        });
      }else{
        isOpen.onAdd({
          error:data.error,
        });
      }
     })
    
     
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <MDTypography>
                Center Name
                  </MDTypography>
                  <MDInput
                    placeholder="Name"
                    
                    style={{ width: '100%' }} 
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
                <MDTypography>
                Email
                  </MDTypography>
                  <MDInput
                    placeholder="Email"
                    type="email"
                    
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                Phone Number
                  </MDTypography>
                  <MDInput
                    placeholder="Phone Number"
                   
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                <MDTypography>
                    Postcode
                  </MDTypography>
                  <MDInput
                    placeholder="Postcode"
                    style={{ width: '100%' }} 
                    onChange={(event) => setPostcode(event.target.value)}
                  />
                </Grid>

                
                <Grid item xs={12}>
                <MDTypography>
                    Location
                  </MDTypography>
                  <MDInput
                    placeholder="Location"
                    style={{ width: '100%' }} 
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </Grid>
                   
                <Grid item xs={12}>
                <MDTypography>
                    Image
                  </MDTypography>
                  <MDInput
                    type="file"
                    style={{ width: '100%' }} 
                    onChange={handleImageChange} 
                  />
                </Grid>

                <Grid item xs={12}>
                <MDTypography>
                   Password
                  </MDTypography>
                  <MDInput
                   type="password"
                    placeholder="Password"
                    style={{ width: '100%' }} 
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Grid>
                
              </Grid>
              <div>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChangeCheck} />}
              label="All Time Available"
            />
            
          </div>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  onClick={handleSave}
                >
                  Add Center 
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
           
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;