import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';

const MyModal = (isOpen) => {

  const [firstName, setFirstName] = useState(isOpen.name[0] || "");
  const [lastName, setLastName] = useState(isOpen.name[1] || "");
  const [email, setEmail] = useState(isOpen.email || "");
  const [phoneNumber, setPhoneNumber] = useState(isOpen.phone_number || "");

  // const [image, setImage] = useState([]);

  // const handleImageChange = (event) => {
  //   ///console.log('event',event.target.files[0].name);
  //   const file = event.target.files[0]; 
  //   if (file) {
  //     setImage(file); 
  //   }
  // }



  useEffect(() => {
    setFirstName(isOpen.name[0]);
    setLastName(isOpen.name[1]);
    setEmail(isOpen.email);
    setPhoneNumber(isOpen.phone_number);

  }, [isOpen])
  const handleSave = (e) => {

    e.preventDefault();

    const formData = new FormData();
    formData.append('firstname', firstName);
    formData.append('lastname', lastName);
    formData.append('email', email);
    formData.append('phone_numbers', phoneNumber);

     console.log('file');
    const patient_id = isOpen.id;
    fetch(process.env.REACT_APP_API_URL + `/patientdetails/updatepatient/${patient_id}`, {
      method: 'PUT', 
      body:formData
    })
      .then(response => response.json())
      .then(data => {

        if(data.message){
          console.log(data.message);

          isOpen.onUpdate({
            message: data.message,
            firstname: firstName,
            lastname: lastName,
            email: email,
            phonenumber: phoneNumber,
            patient_id: patient_id
          });
        }else{
        
          isOpen.onUpdate({
            error: data.error,
          
          });
        }
       
      })

  };
  return (
    <>


      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            <MDBox>
            <MDBox component="form" role="form" onSubmit={handleSave}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDTypography>
                    First Name
                  </MDTypography>
                  <MDInput
                    placeholder="First Name"
                    value={firstName}
                    style={{ width: '100%' }}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                    Last Name
                  </MDTypography>
                  <MDInput
                    placeholder="Last Name"
                    value={lastName}
                    style={{ width: '100%' }}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                    Email
                  </MDTypography>
                  <MDInput
                    placeholder="Email"
                    type="email"
                    value={email}
                    style={{ width: '100%' }}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <MDTypography>
                    Phone Number
                  </MDTypography>
                  <MDInput
                    placeholder="Phone Number"
                    value={phoneNumber}
                    style={{ width: '100%' }}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>

               

              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  type="submit"
                  color="success"
                 
                >
                  Update
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;