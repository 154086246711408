import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal, Box } from '@mui/material';

const MyModal = (isOpen) => {

  const [Name, setName] = useState(  "");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState( "");
  const [oneCenterDetail, setOneCenterDetail] = useState({});
  
  const [image, setImage] = useState([]);

  const handleImageChange = (event) => {
    ///console.log('event',event.target.files[0].name);
    const file = event.target.files[0]; 
    if (file) {
      setImage(file); 
    }
  }


  useEffect(()=>{
    setName(isOpen.name);
    setEmail(isOpen.email);
    setPhoneNumber(isOpen.phone_number );
  },[isOpen])

  useEffect(()=>{
    fetch( process.env.REACT_APP_API_URL+`/auth/getcenterbyid/${isOpen.id}`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
      setOneCenterDetail(data);
     
    })
  },[isOpen])
  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', Name);
    formData.append('email', email);
    formData.append('image', image); // Ensure 'image' is a File or Blob object
    formData.append('phone_number', phoneNumber);
    

    const center_id=isOpen.id;
  fetch( process.env.REACT_APP_API_URL+`/auth/updatecenter/${center_id}`, {
        method: 'PUT',
       
        body: formData
      })
      .then(response => response.json())
      .then(data => {
         if(data.message){
          isOpen.onUpdate({
            message:data.message,
            center_id:center_id,
            name:Name,
            email:email,
            phonenumber:phoneNumber,
          });
         }else{
          isOpen.onUpdate({
           error:data.error
          });
         }
       
       
      })
     
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            <MDBox>
            <MDBox component="form" role="form" onSubmit={handleSave}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <MDTypography>
                Name
                  </MDTypography>
                  <MDInput
                    placeholder="Name"
                    value={Name}
                    style={{ width: '100%' }} 
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
                <MDTypography>
                Email
                  </MDTypography>
                  <MDInput
                    placeholder="Email"
                    type="email"
                    value={email}
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>


                <Grid item xs={12}>
                <MDTypography>
                Phone Number
                  </MDTypography>
                  <MDInput
                    placeholder="Phone Number"
                    value={phoneNumber}
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>

                
                <Grid item xs={12}>
                <MDTypography>
                    Image
                  </MDTypography>
                  <MDInput
                    type="file"
                    style={{ width: '100%' }} 
                    onChange={handleImageChange} 
                  />
                  {oneCenterDetail.image &&
                  <Box
                    component="img"
                    src={oneCenterDetail.image}
                    alt="image"
                    sx={{ width: 150, height: 100  ,mt:2}} 
                  />
                  }
                </Grid>

              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  type='submit'
                >
                  Update
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;