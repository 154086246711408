/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import referralTableData from "layouts/tables/data/referralTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import MyModal from '../../components/ReferralEditPopUp';
import DeleteModel from '../../components/ReferralDeletePopUp';
import StatusModel from '../../components/ReferralStatusPopUp';
import AddReferralModel from '../../components/ReferralAddPopUp';



import { useLocation, useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";




function Tables() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isAddReferralOpen, setIsAddReferralOpen] = useState(false);
  const [id, setID] = useState('');
  const [delete_id, setDeleteID] = useState('');
  const [status_id, setStatusID] = useState('');

  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [clinic_name, setClinicName] = useState('');

  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);




  const [search, setSearch] = useState('');







  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={title}
      content={message}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={title}
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const seprateName = name.split(" ");
  const openEditPopup = (data) => {
    if (!data.referral_id) {
      setID(data.id.props.children);
      setName(data.name.props.children);
      setEmail(data.email.props.children);
      setPhoneNumber(data.phone.props.children);
      setClinicName(data.clinic.props.children);


      setIsEditOpen(true);
    } else {
      setID(data.referral_id);
      setName(data.firstname + " " + data.lastname);
      setEmail(data.email);
      setPhoneNumber(data.phonenumber);
      setClinicName(data.clinicname);

      setIsEditOpen(true);
    }
  }

  const openDeletePopUp = (data) => {
    if(data.referral_id){
      setDeleteID(data.referral_id);
      setIsDeleteOpen(true);
    }else{
      setDeleteID(data.id.props.children);
      setIsDeleteOpen(true);
    }
   

  }

  const deleteRowById = (id) => {
    setRows(prevRows => prevRows.filter(row => row.id.props.children !== id));
  };

  const openStatusPopUp = (data) => {
    if(data.referral_id){
      setStatusID(data.referral_id);
      setStatus(data.status);
      setIsStatusOpen(true);
    }else{
      setStatusID(data.id.props.children);
    setStatus(data.status);
    setIsStatusOpen(true);
    }
    
  }

  const openAddReferralPopUp=()=>{
    setIsAddReferralOpen(true);
  }



  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const closeEditPopup = () => {
    setIsEditOpen(false);

  }
  const closeDeletePopup = () => {
    setIsDeleteOpen(false);
  }
  const closeStatusPopUp = () => {
    setIsStatusOpen(false);
  }

  
  const CloseReferralPopUp = () => {
    setIsAddReferralOpen(false);
  }
  const column = { Header: "Action", accessor: "action" };
  const column2 = { Header: "Status", accessor: "status" };
  const { columns: tableColumns, rows: tableRows } = referralTableData();
  //const row={edit: 'edit'}

  const update = (value) => {
    if(value.message){
      setIsEditOpen(false);
      updateRowById(value.referral_id, value);
      setMessage(value.message);
      setTitle('Updated');
      openSuccessSB();
    }else{
      setIsEditOpen(false);
      //updateRowById(value.referral_id, value);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }

  
  }

  const deleteUpdate = (value) => {
    if(value.message){
      setIsDeleteOpen(false);
      deleteRowById(value.id);
      setMessage(value.message);
      setTitle('Deleted');
      openSuccessSB();
  
    }else{
      setIsDeleteOpen(false);
      ///deleteRowById(value.id);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
  
    }

  }
  const statusUpdate = (value) => {
    if(value.message){
      setIsStatusOpen(false);
      updateRowStatusById(value);
      setMessage(value.message);
      setTitle('Updated');
      openSuccessSB();
    }else{
      setIsStatusOpen(false);
     /// updateRowStatusById(value);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }
   

  }

  
  const addReferral = (value) => {
    setIsAddReferralOpen(false);
    ///updateRowStatusById(value);
    if(value.message){
    updateData(value.referral_id,value);
    setMessage(value.message);
    setTitle('Added');
    openSuccessSB();
    }else if(value.error){
    setMessage(value.error);
    setTitle('Error');
    openErrorSB();
    }

  }

  useEffect(() => {

    if (rows.length > 0 || search != '') {
      return
    }
    const updatedRows = tableRows.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium">
            <span onClick={() => openEditPopup(row)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>,
        status:
          <MDTypography component="a" href="#" variant="caption" color={row.status == true ? 'success' : 'error'} fontWeight="medium" onClick={() => openStatusPopUp(row)}>
            {row.status == true ? <MDBox ml={-1}>
              <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
            </MDBox> : <MDBox ml={-1}>
              <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
            </MDBox>}
          </MDTypography>
      }

    ))

    setColumns([...tableColumns, column, column2]);
    setRows(updatedRows);

  }, [tableColumns]);
  const updateRowById = (id, updatedValues) => {
    // Find the index of the row with the specific id
    const rowIndex = rows.findIndex(row => row.id.props.children === id);
    if (rowIndex !== -1) {
      // Update the row data immutably

      const updatedRows = rows.map((row, index) =>
        index === rowIndex
          ? {
            ...row,
            phone: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.phonenumber}</MDTypography>,
            name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.firstname + " " + updatedValues.lastname}</MDTypography>,
            clinic: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.clinicname}</MDTypography>,
            email: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.email}</MDTypography>,
            action:
              <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
                <span onClick={() => openEditPopup(updatedValues)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(updatedValues)}> <DeleteIcon fontSize="medium" color="error" /> </span>
              </MDTypography>,

          }
          : row
      );

      setRows(updatedRows);
    }
  }
  const updateRowStatusById = (value) => {
    // Find the index of the row with the specific id
    const rowIndex = rows.findIndex(row => row.id.props.children === value.id);

    if (rowIndex !== -1) {
      // Update the row data immutably

      const updatedRows = rows.map((row, index) =>

        index === rowIndex
          ? {
            ...row,
            status:
              <MDTypography component="a" href="#" variant="caption" fontWeight="medium" onClick={() => openStatusPopUp({ ...row, status: value.status })}>
                {value.status == true ? (<MDBox ml={-1}>
                  <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
                </MDBox>) : (<MDBox ml={-1}>
                  <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
                </MDBox>)}
              </MDTypography>

          }
          : row
      );

      setRows(updatedRows);
    }
  }

  const updateData = (id,values) => {
   
    const newRow = {id: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{id}</MDTypography>,
      name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.firstname + " " + values.lastname}</MDTypography>,
      email: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.email}</MDTypography>,
      clinic: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.clinicname}</MDTypography>,
      phone: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.phonenumber}</MDTypography>,

      action:
        <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
          <span onClick={() => openEditPopup(values)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(values)}> <DeleteIcon fontSize="medium" color="error" /> </span>
        </MDTypography>,
      status:
      <MDTypography component="a" href="#" variant="caption" color={values.status == true ? 'success' : 'error'} fontWeight="medium" onClick={() => openStatusPopUp(values)}>
        {values.status == true ? <MDBox ml={-1}>
          <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
        </MDBox> : <MDBox ml={-1}>
          <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
        </MDBox>}
      </MDTypography>
}

 setRows([...rows, newRow]);

}

  const handleSearch = (query) => {


    const searchTerm = query.toLowerCase();
    console.log("tableRows>", tableRows)
    const filteredData = tableRows.filter((row) => {
      //console.log(row.name.props.children.toLowerCase())
      const rowNameValue = row.name.props.children.toLowerCase();
      const rowClinicValue = row.clinic.props.children?row.clinic.props.children.toLowerCase():'';
      const rowStatusValue = row.status ? 'active' : 'deactive';
      return rowNameValue.includes(searchTerm) ||
        rowStatusValue == searchTerm ||
        rowClinicValue.includes(searchTerm);
    });

    console.log("filteredData>", filteredData)

    const updatedRows = filteredData.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
            <span onClick={() => openEditPopup(row)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>,
        status:
          <MDTypography component="a" href="#" variant="caption" color={row.status == true ? 'success' : 'error'} fontWeight="medium" onClick={() => openStatusPopUp(row)}>
            {row.status == true ? <MDBox ml={-1}>
              <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
            </MDBox> : <MDBox ml={-1}>
              <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
            </MDBox>}
          </MDTypography>
      }

    ))
    setRows(updatedRows);


  };





  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Referrals
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>

                <MDBox width="12rem" ml="auto" mr="1em">
                  <MDInput
                    placeholder="Search... [name,clinic,status]"
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      handleSearch(currentTarget.value);
                    }}
                  />
                </MDBox>

                <MDBox width="12rem" ml="auto" mr="1em" mt='2rem'>
              <MDButton variant="gradient" color="info" onClick={openAddReferralPopUp} >Add Referral</MDButton>
              </MDBox>

              </MDBox>

              <MDBox pt={3}>

                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={10}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <MyModal isOpen={isEditOpen} onClose={closeEditPopup} id={id} name={seprateName} email={email} phone_number={phone_number} clinic_name={clinic_name} onUpdate={update} />
      <DeleteModel isdeleteOpen={isDeleteOpen} ondeleteClose={closeDeletePopup} delete_id={delete_id} ondeleteUpdate={deleteUpdate} />
      <StatusModel isstatusOpen={isStatusOpen} onstatusClose={closeStatusPopUp} status_id={status_id} status={status} onstatusUpdate={statusUpdate} />
      <AddReferralModel isOpen={isAddReferralOpen} onClose={CloseReferralPopUp} onAdd={addReferral} />
      <Footer />
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Tables;
