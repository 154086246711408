import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';
import {Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { VpnLock } from '@mui/icons-material';
import { valid } from 'chroma-js';

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
  }
`;
const MyModal = (isOpen) => { 

  const [data,setData]=useState([]);
  const [centerId,setCenterId]=useState('');
  const [scantypeId,setscantypeId]=useState('');
  const [scantype,setScantype]=useState([]);
  const [name,setName]=useState('');
  const [totalcount,setCount]=useState('');
  const [scantypename,setscantypeName]=useState('');
  const [discountedValue,setDiscountValue]=useState('');
  const [selectedOption, setSelectedOption] = useState("Select center");
  const [selectedOption2, setSelectedOption2] = useState("Select scantype");
  const [selectedOption3, setSelectedOption3] = useState("Select count");

  ///console.log('doctor_name',selectedOption);
   ////console.log('discountedValue',discountedValue);

   useEffect(() => {
    setSelectedOption("Select center");
    setSelectedOption2("Select scantype");
    setSelectedOption3("Select count");
    setDiscountValue('');
   },[isOpen])



const count=[2,3,4];
useEffect(() => {
  fetch(process.env.REACT_APP_API_URL + `/auth/getallcenters`,)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setData(data);
    });
},[])

useEffect(() => {
  fetch(process.env.REACT_APP_API_URL + `/auth/takedatascantype`,)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setScantype(data);
    });
},[])


const handleChange = (event) => {

  //console.log("event target",event.target);
  const value=event.target.value;
  const split=value.split('#');

 setCenterId(split[1]);
 setName(split[0]);
 setSelectedOption(event.target.value); 
};

const handleChange2 = (event) => {

  //console.log("event target",event.target);
  const value=event.target.value;
  const split=value.split('#');

 setscantypeId(split[1]);
 setscantypeName(split[0]);
 setSelectedOption2(event.target.value); 
};

const handleChange3= (event) => {

  ///console.log("event target",event.target);
  const value=event.target.value;
 setCount(value);
 setSelectedOption3(event.target.value); 
};

const handleChange4= (event) => {
setDiscountValue(event.target.value);
  
};

const handleSave = () => {


fetch( process.env.REACT_APP_API_URL+`/search/creatediscount`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        scantypeid:scantypeId,
        centerid:centerId,
        length:totalcount,
        discount:discountedValue
      })
    })
    .then(response => response.json())
    .then(data => {
      if(data.message){
        isOpen.onUpdate({
          message:data.message,
        });
      }else{
        isOpen.onUpdate({
          error:data.error
        });
      }
     
    })
   
};

const renderValue = (selected) => {
  ///console.log("selected>",selected)
  return selected.split('#')[0];
};

const renderValue2 = (selected) => {
  ///console.log("selected>",selected)
  return selected;
};

  return (
    <>
    <Modal
    open={isOpen.isOpen}
    onClose={isOpen.onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={isOpen.isOpen}>
      <MDBox
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        shadow="md"
        borderRadius="lg"
        p={4}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
        }}
      >
        <MDBox width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h6">Select Center</MDTypography>
              <FormControl fullWidth>
                <StyledSelect
                  id="demo-simple-select"
                  value={selectedOption}
                  onChange={handleChange}
                  renderValue={renderValue}
                >
                  <MenuItem value="Select center">Select Center </MenuItem>
                  {data.map((item) => (
                 <MenuItem key={item.id} value= {item.name+"#"+item.id} >
                   {item.name}
                 </MenuItem>
               ))}
                </StyledSelect>
              </FormControl>
            </Grid>
          
            <Grid item xs={12}>
              <MDTypography variant="h6">Select ScanType</MDTypography>
              <FormControl fullWidth>
                <StyledSelect
                  id="demo-simple-select"
                  value={selectedOption2}
                  onChange={handleChange2}
                  renderValue={renderValue}
                >
                  <MenuItem value="Select center">Select scantype </MenuItem>
                  {scantype.map((item) => (
                 <MenuItem key={item.id} value= {item.name+"#"+item.id} >
                   {item.name}
                 </MenuItem>
               ))}
                </StyledSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6">BodyParts Count</MDTypography>
              <FormControl fullWidth>
                <StyledSelect
                  id="demo-simple-select"
                  value={selectedOption3}
                  onChange={handleChange3}
                  renderValue={renderValue2}
                >
                  <MenuItem value="Select count">Select count</MenuItem>
                  {count.map((item) => (
                 <MenuItem key={item} value= {item} >
                   {item}
                 </MenuItem>
               ))}
                </StyledSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6">Discount</MDTypography>
              <MDInput
                
                 type="number"
                 name="price"
                 onChange={(e)=>handleChange4(e)}
                 style={{ width: '100%' }}
                 placeholder="e.g., 50%"
                pattern="^\d+%$"
                required
               />
            </Grid>
            
          </Grid>

          <MDBox
            display="flex"
            justifyContent="flex-end"
            gap={2}
            mt={2}
          >
            <MDButton
              color="success"
              onClick={handleSave}
            >
              Save
            </MDButton>
            <MDButton
              color="secondary"
              variant="outlined"
              onClick={isOpen.onClose}
            >
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Fade>
  </Modal>


    </>
  );
};


export default MyModal;