import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal, Box, Typography } from '@mui/material';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { VpnLock } from '@mui/icons-material';
import { valid } from 'chroma-js';
import MDBadge from 'components/MDBadge';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
  }
`;
const MyModal = (isOpen) => {



  const [days, setDays] = useState([]);
  const [availabities, setAvailabities] = useState([]);
  const [selectedavailabities, setSelectedAvailbities] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Select day");
  const [allavailabities, setAllAvailabities] = useState([]);
  const [DBAvailabilities,setDBAvailabilities]=useState([]);
  const [check,setCheck]=useState(false);
  const [checkRun,setCheckRun]=useState(true);

  console.log(selectedOption);

  const arr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const availabitie = ['08:00','08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00'];

  useEffect(() => {
    setDays(arr);
  }, [])

  useEffect(() => {
    setSelectedOption('Select day');
    setAvailabities([]);
    setSelectedAvailbities([]);
    setAllAvailabities([]);
    setCheckRun(true);
  }, [isOpen])

  console.log('selected', selectedavailabities);
  console.log('all avaliities>>>>>>>>>.', allavailabities);



  const handleSelectOption = (option) => {
    const existing = allavailabities.find((data) => data.selectedOption === option);
    console.log('existing', existing);
    if (existing) {
      setSelectedAvailbities(existing.availabilities);
      setAvailabities((prevavailabity) =>
        prevavailabity.filter(
          (item) => !existing.availabilities.includes(item)
        )
      );
    } else {
      setSelectedAvailbities([]);
    }


  };

   useEffect(() => {
    if(selectedOption=='Select day'){
      return
    }
    try{
    fetch(process.env.REACT_APP_API_URL + "/aby/availability/" + isOpen.centerId, {
      method: 'GET',
    
    }).then((response) => {
      return response.json()
    }).then((data) => {
      if(!data.error){
       
     data.map((item)=>{
      if(checkRun){     
      setAllAvailabities(prevAvailabilities => {
        // Check if the dayname already exists
        const existingIndex = prevAvailabilities.findIndex(
          availability => availability.selectedOption === item.dayname
        );


        if (existingIndex !== -1) {
          console.log('updatesss',selectedOption)
          // Update the existing entry
          const updatedAvailabilities = [...prevAvailabilities];
          updatedAvailabilities[existingIndex] = {
            ...updatedAvailabilities[existingIndex],
            availabilities: item.time,
          };
          return updatedAvailabilities;
        } else {
         
          // Add a new entry
          return [
            ...prevAvailabilities,
            { selectedOption: item.dayname, availabilities: item.time },
          ];
        }
      
      });
      setCheckRun(false);
    }
       if(selectedOption==item.dayname){
        setCheck(true);
        setDBAvailabilities(item.time);
        setSelectedAvailbities(item.time);
     }
      console.log('itemmmmm>>>>>>',data);
    }) 
  }else{
    console.log('no data found');
  }   
    })
  }catch(error){
    console.log('error',error);
  }
  }, [selectedOption])

  useEffect(()=>{
    if(selectedOption!='Select day'){
      const filteredData = availabitie.filter(part =>
        !selectedavailabities.some(selectedPart => selectedPart === part)
      );
      console.log('filterddataa',filteredData);
      setAvailabities(filteredData);
    }

  },[selectedavailabities])

  const handleChange = (event) => {

    if (event.target.value != 'Select day') {
      setAvailabities(availabitie);
      const day = event.target.value;
      setSelectedOption(day);
    } else {
      setSelectedOption(event.target.value);
      setAvailabities([]);
      setSelectedAvailbities([]);
    }
  };




  const handlesubmit = (e) => {
    e.preventDefault()

    const formattedData = allavailabities.reduce((acc, curr) => {
      acc[curr.selectedOption] = curr.availabilities;
      return acc;
  }, {});
  
  console.log(formattedData);
console.log('check>>>>>>>>>>>>>>>>',check);
   if(check){

    fetch(process.env.REACT_APP_API_URL + `/aby/updateavailability/${isOpen.centerId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        time: formattedData,
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.message) {
          isOpen.onUpdate({
            message: data.message,
          });
        } else {
          isOpen.onUpdate({
            error: data.error
          });
        }
      });

  }else{
    fetch(process.env.REACT_APP_API_URL + `/aby/createavailability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        time: formattedData,
        centers_id_fkey:isOpen.centerId
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.message) {
          isOpen.onUpdate({
            message: data.message,
          });
        } else {
          isOpen.onUpdate({
            error: data.error
          });
        }
      });
  }
}

  const renderValue = (selected) => {
    return selected;
  };

  const selectedavailabity = (data) => {

    setAvailabities((prevavailabity) =>
      prevavailabity.filter((item) => item !== data)
    );
    setSelectedAvailbities((prevavailabity) => [...prevavailabity, data]);


  }

  useEffect(() => {

    if (selectedOption != 'Select day') {
      const exists = allavailabities.find((data) => data.selectedOption === selectedOption);

      if (exists) {

        // Update existing item
        setAllAvailabities((prevavailabity) =>
          prevavailabity.map((item) =>
            item.selectedOption === selectedOption
              ? { ...item, availabilities: [...selectedavailabities] }
              : item
          )
        );
        //setSelectedAvailbities([]);
      } else {
        // Add new item
        setAllAvailabities((prevavailabity) => [
          ...prevavailabity,
          { selectedOption, availabilities: selectedavailabities },
        ]);
        //setSelectedAvailbities([]);
      }
    }

  }, [selectedavailabities])



  const handleDelete = (item) => {

    setSelectedAvailbities((prevavailabity) => prevavailabity.filter((part) => part !== item));

    setAvailabities((prevAvailabaites) => [...prevAvailabaites, item]);
  }

  return (
    <>
      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            <MDBox width="100%">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDTypography variant="h6">Select Day</MDTypography>
                  <FormControl fullWidth>
                    <StyledSelect
                      id="demo-simple-select"
                      value={selectedOption}
                      onChange={handleChange}
                      renderValue={renderValue}
                    >
                      <MenuItem value={"Select day"}>
                        Select day
                      </MenuItem>
                      {days.map((item) => (
                        <MenuItem key={item} value={item} onClick={() => handleSelectOption(item)}>
                          {item}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px', gap: '20px' }}>

                {/* Availabilities Box */}
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ marginBottom: '16px' }}>Availabilities</Typography>
                  <Box
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '300px',
                      border: '1px solid gray',
                      borderRadius: '10px',
                      overflowY: 'auto', // Enable vertical scrolling if needed
                      overflowX: 'hidden', // Disable horizontal scrolling
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap', // Wrap items to the next line if needed
                      alignItems: 'flex-start', // Align items from the start of the container
                      padding: '8px',
                      gap: '8px', // Add space between items
                    }}
                  >
                    {availabities.map((item) => (
                      <MDTypography
                        component="a"
                        href="#"
                        key={item}
                        onClick={() => selectedavailabity(item)}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '4px', // Add margin between items
                          textDecoration: 'none', // Remove underline from link if necessary
                        }}
                      >
                        <MDBadge badgeContent={item} color="success" variant="gradient" size="lg" />
                      </MDTypography>
                    ))}
                  </Box>
                </Box>

                {/* Selected Availabilities Box */}
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ marginBottom: '16px' }}>Selected Availabilities</Typography>
                  <Box
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '300px',
                      border: '1px solid gray',
                      borderRadius: '10px',
                      overflowY: 'auto', // Enable vertical scrolling if needed
                      overflowX: 'hidden', // Disable horizontal scrolling
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap', // Wrap items to the next line if needed
                      alignItems: 'flex-start', // Align items from the start of the container
                      padding: '8px',
                      gap: '8px', // Add space between items
                    }}
                  >
                    {selectedavailabities.map((item) => (
                      <MDTypography
                        component="a"
                        href="#"
                        key={item}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '4px', // Add margin between items
                          textDecoration: 'none', // Remove underline from link if necessary
                        }}
                      >
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                          <span
                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                            onClick={() => handleDelete(item)}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </span>
                          <MDBadge badgeContent={item} color="success" variant="gradient" size="lg" />
                        </MDBox>
                      </MDTypography>
                    ))}
                  </Box>
                </Box>
              </Box>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  onClick={handlesubmit}
                >
                  Submit
                </MDButton>



                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>

    </>
  );
};

export default MyModal;