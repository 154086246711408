/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/signin_background.png";
import axios from "axios";

function Verify() {

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email') || ''
    const user_role= searchParams.get('user') || ''
     

    try {
      console.log(email, code);
      const response = await axios.post(
        process.env.REACT_APP_API_URL+`/auth/verify-login-${user_role}`,
        {
          email,
          verificationcode: code,
        }
      );

      const jwt_token = response.data.token;
      const user = JSON.stringify(response.data.user);
      localStorage.setItem("jwt_token", jwt_token);
      localStorage.setItem("user", user);
      navigate( `/bookings`);
      setError("");
    } catch (error) {
      setError(error.response.data.error);
      console.error("error is here", error);
    }
  }


  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Verify Email
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput type="text" label="Verification Code" 
                    id="verify-code"
                    name="verify-code"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)} variant="standard" fullWidth />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" type='submit' color="info" fullWidth>
                Verify
              </MDButton>
            </MDBox >
            <MDBox mt={3} mb={1} textAlign="center">
            {error && <p className="text-red-500">{error}</p>}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Verify;
