import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(isdeleteOpen) {


        const handleDelete=()=>{
            const booking_id=isdeleteOpen.delete_id;
          fetch( process.env.REACT_APP_API_URL+`/booking/deletebooking/${booking_id}`, {  
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          
        })
        .then(response => response.json())
        .then(data => { 
          if(data.message){
            console.log(data.message);
            isdeleteOpen.ondeleteUpdate({
              message:data.message,
              id:booking_id,
            });
          }else{
            
            isdeleteOpen.ondeleteUpdate({
              error:data.error,
              
            });
          }
        
        })
        .catch((error) => {
          console.error('Error:', error);
        });


        }



  return (
    <React.Fragment>
   
      <Dialog
        open={isdeleteOpen.isdeleteOpen}
        onClose={isdeleteOpen.ondeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this Booking?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={isdeleteOpen.ondeleteClose}>Cancle</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
